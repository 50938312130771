[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "colibri" !important;
	speak-as: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-accueil:before {
	content: "\e900";
}
.icon-adresse:before {
	content: "\e901";
}
.icon-ajouter:before {
	content: "\e902";
}
.icon-annee:before {
	content: "\e903";
}
.icon-article_1:before {
	content: "\e904";
}
.icon-article:before {
	content: "\e905";
}
.icon-cart:before {
	content: "\e906";
}
.icon-:before {
	content: "\e906";
}
.icon-client:before {
	content: "\e907";
}
.icon-come-back:before {
	content: "\e908";
}
.icon-compte:before {
	content: "\e909";
}
.icon-creation-article:before {
	content: "\e90a";
}
.icon-devis:before {
	content: "\e90b";
}
.icon-docs-client:before {
	content: "\e90c";
}
.icon-docs:before {
	content: "\e90d";
}
.icon-email:before {
	content: "\e90e";
}
.icon-entree-sortie:before {
	content: "\e90f";
}
.icon-favoris:before {
	content: "\e910";
}
.icon-fleche-down:before {
	content: "\e911";
}
.icon-fleche-up:before {
	content: "\e912";
}
.icon-fournisseurs:before {
	content: "\e913";
}
.icon-liste:before {
	content: "\e914";
}
.icon-parametres:before {
	content: "\e915";
}
.icon-plus-info-client:before {
	content: "\e916";
}
.icon-recherche-ref:before {
	content: "\e917";
}
.icon-recherche:before {
	content: "\e918";
}
.icon-ref-debitee:before {
	content: "\e919";
}
.icon-saisie:before {
	content: "\e91a";
}
.icon-scan-info-article:before {
	content: "\e91b";
}
.icon-scan:before {
	content: "\e91c";
}
.icon-secteur:before {
	content: "\e91d";
}
.icon-societe:before {
	content: "\e91e";
}
.icon-stats-mensuelles:before {
	content: "\e91f";
}
.icon-stats-univers:before {
	content: "\e920";
}
.icon-stocks:before {
	content: "\e921";
}
.icon-store:before {
	content: "\e922";
}
.icon-supprimer:before {
	content: "\e923";
}
.icon-telephone:before {
	content: "\e924";
}
.icon-univers:before {
	content: "\e925";
}
.icon-valide:before {
	content: "\e926";
}
