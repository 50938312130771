/**********/
/* GESTION DES TITRES */
/**********/
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
h1 {
	color: #fff;
	font-size: clamp(3rem, 4rem, 6rem);
	font-weight: 600;
	padding: 2rem;
	/* line-height: clamp(3.4rem, 4rem, 4.4rem); */
}
h2 {
	font-size: clamp(2rem, 2.5rem, 3rem);
}
h3 {
	font-size: clamp(16rem, 1.6rem, 1.8rem);
}
h4 {
	font-size: clamp(1.4rem, 1.5rem, 1.6rem);
}
p,
a {
	font-size: clamp(1.6rem, 1.7rem, 1.8rem);
	line-height: 1;
}
/****************
* GESTION FONT ICONS
****************/
@font-face {
	font-family: "colibri";
	src: url("../fonts/colibri.eot?1cdhsm");
	src: url("../fonts/colibri.eot?1cdhsm#iefix") format("embedded-opentype"),
		url("../fonts/colibri.ttf?1cdhsm") format("truetype"),
		url("../fonts/colibri.woff?1cdhsm") format("woff"),
		url("../fonts/colibri.svg?1cdhsm#colibri") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
/***************
* GESTION FONT NEW FRANK
***************/
@font-face {
	font-family: "new_frankbold";
	src: url("../fonts/fonnts.com-new_frank_bold-webfont.woff2") format("woff2"),
		url("../fonts/fonnts.com-new_frank_bold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "new_nordmedium";
	src: url("../fonts/fonnts.com-new_nord_medium-webfont.woff2") format("woff2"),
		url("../fonts/fonnts.com-new_nord_medium-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "new_frankregular";
	src: url("../fonts/fonnts.com-new_frank_regular-webfont.woff2")
			format("woff2"),
		url("../fonts/fonnts.com-new_frank_regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "new_franklight";
	src: url("../fonts/fonnts.com-new_frank_light-webfont.woff2") format("woff2"),
		url("../fonts/fonnts.com-new_frank_light-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "new_frankthin";
	src: url("../fonts/fonnts.com-new_frank_thin-webfont.woff2") format("woff2"),
		url("../fonts/fonnts.com-new_frank_thin-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

:root {
	--bold: "new_frankbold";
	--medium: "new_nordmedium";
	--regular: "new_frankregular";
	--light: "new_franklight";
	--thin: "new_frankthin";
}
