/* Styles globaux */
header.gris{
  background-color: var(--grisprez);
}
.overlay-gris {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 2, 2, 0.5); /* Overlay bleu semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.loading-container {
  text-align: center;
}
.loading-gif {
  width: 100px;
  height: 100px;
}
.container-portail {
  display: flex;
  justify-content: center; /* Centre horizontalement les éléments dans le conteneur */
}
.logo-header-portail img{
  width: 25%;
}
.mobile-logo-colibrisco-portail{
  display: block;
  height: inherit;
  -o-object-fit: contain;
  object-fit: contain;
  margin-left: 1rem;
  margin-top: -3.5rem;
  width: 12%;
}
.logo-magasin{
  margin-top: -3rem;
  justify-content: left;
}
.header-panel {
  width: 119%;
}
.magConnexion-nomMag{
  margin-left: -4rem;
}
.magasin-input{
  margin-top: -5rem;
  margin-left: -4rem;
}
.magasin-nom{
  padding-top: 9rem;
  padding-left: 2rem;
}
p.magasin-connexion{
  font-size: 0.875rem;
  color: black;
  display: contents;
  padding-left: 6.5rem;
  margin-top: -1rem;
}
p.nom-magasin{
  font-size: 1rem;
  font-family: var(--bold);
  text-transform: uppercase;
  color: black;
  margin-top: -1rem;
}
input.input-rechercher{
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0px;
}
input.input-rechercher::placeholder{
  font-size: 1.2rem;
  color: var(--grisgadi);
  align-items: center;
}
.input-group-ref {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.search-header {
  border-radius: 0 0.5rem 0.5rem 0;
  height: 5rem;
  width: 5rem;
}
.input-group-ref i::before{
  padding: 1rem 0;
}
header.no-before::before{
  background-color: var(--bleufond);
  content: "";
  display: block;
  height: 0.1rem;
  width: 100%;
}

h1.vos-applications{
  color: var(--bleugadisat);
  font-size: 1.875rem;
  font-family: var(--bold);
}
.fa-stack-1x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  margin-left: -1rem;
  margin-top: -0.3rem;
}

.fa-color-power {
  color: var(--bleugadisat);;
}

.col-70-portail {
  display: flex;
  justify-content: center; /* Centre horizontalement le contenu de .col-70 */
  width: 100%; /* Ajuste la largeur de .col-70 si nécessaire */
}

p.login {
  margin: 0; /* Retire les marges par défaut autour du texte */
  color: var(--bleugadisat);
  display: contents;
}

.blanc-portail {
  background-color: var(--blanc);
  height: 100%; /* Assure que le main prend toute la hauteur de la fenêtre */
  display: flex;
  justify-content: center; /* Centre les éléments horizontalement dans le main */
}

.portail main {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.cercle-portail {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.icon-rond {
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  font-size: 4rem;
  color: var(--blanc);
  background-color: var(--bleufond);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}


.cercle-portail {
  text-align: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.d-rond {
  display: flex;
  justify-content: center; /* Centre les éléments horizontalement */
  align-items: center;    /* Centre les éléments verticalement */
}

.connexion .d-rond p {
	color: var(--bleugadisat);
	font-size: 1rem;
	line-height: 1rem;
	width: 100%;
  margin-top: 1rem;
}

/* Media Queries pour les écrans de téléphone */
@media (max-width: 767px) {

  .rond-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  h1.vos-applications{
    display: flex;
    text-transform: none;
  }
}

/* Media Queries pour les écrans d'iPad */
@media (min-width: 768px) and (max-width: 1024px) {
    .cercle-portail {
        width: 150px; /* Ajuste la taille du cercle pour les écrans de taille moyenne */
        height: 150px;
        font-size: 20px; /* Ajuste la taille de l'icône */
        margin-right: 2rem; /* Ajuste les marges */
        margin-top: 2rem;
    }

    .portail main {
        padding: 2.5rem 0; /* Ajuste le padding pour les écrans de taille moyenne */
    }

    .rond-container {
        margin-top: 4rem; /* Ajuste la marge pour les écrans de taille moyenne */
    }
}