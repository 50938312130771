/* FORMULAIRES */
input {
	background: var(--blanc);
	border: none;
	border-radius: 0.5rem;
	box-shadow: none;
	color: #03143e;
	font-size: 1.8rem;
	width: 100%;
}
input:hover,
input:focus {
	background: var(--blanc);
}
/* BOUTONS */
button {
	font-family: var(--bold);
	font-size: 2rem;
	justify-content: center;
}
input:hover,
button:hover {
	cursor: pointer;
}
input,
button {
	align-items: center;
	border-radius: 5px;
	display: flex;
	height: 5rem;
	padding: 0 1.5rem;
}
button i {
	font-size: clamp(1.8rem, 2rem, 2rem);
	margin-right: 2rem;
}
.btn {
	align-items: center;
	background-color: var(--bleufond);
	border: none;
	border-radius: 0.5rem;
	color: var(--blanc);
	cursor: pointer;
	display: flex;
	justify-content: center;
}
.btn-famille {
	background-color: var(--bleufond);
	color: var(--blanc);
	border: none;
	border-radius: 5px;
	cursor: pointer;
	width: 34rem;
	height: 5.5rem;
	margin-bottom: 3rem;
	margin-left: 1.3rem;
	margin-right: 8rem;
}
.btn-connexion,
.btn-mdp-oublie{
	font-size: 1.5rem;
	font-family: var(--regular);
}

.btn-outline {
	background-color: transparent;
	border: 0.1rem solid #a9aeb6;
	color: #a9aeb6;
}
.btn-plain {
	color: #fff;
}
.btn-2175FF {
	background-color: #2175ff;
}
.btn-041D5B {
	background-color: var(--bleugadisat);
}

.search-button {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	height: 5rem;
	font-family: var(--medium);
	font-size: 1.6rem;
	margin-left: -1rem;
	width: 7rem;
}

@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 992px) {
	.btn-connexion{
		margin-top: 2rem;
		width: 20rem;
	}
	.identifiant{
		margin-bottom: 2rem;
	}
	.btn-mdp-oublie{
		width: 20rem;
	}
}
@media screen and (min-width: 1200px) {
	button {
		transition: all 0.3s ease-in-out;
	}
	.btn:hover {
		background: var(--bleufondhover);
		color: var(--blanc);
	}
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
