.connexion {
	background-color: var(--bleugadisat);
}
main{
	height: 100vh;
}
.connexion main {
	display: flex;
	flex-direction: column;
	padding: 2rem 0;
}
.connexion main .container {
	padding: 0 2rem;
}
.bleu{
	background-color: var(--bleugadisat);
}
.connexion main .container {
	padding: 0 2rem;
}
h1 {
	font-family: var(--medium);
	font-size: clamp(5rem, 5.5rem, 6rem);
	/* line-height: clamp(6rem, 6.5rem, 7rem); */
	text-transform: uppercase;
}
.connexion .col-50:first-of-type {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.connexion .col-10:first-of-type {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.connexion p {
	color: var(--blanc);
	font-size: clamp(1.8rem, 2rem, 2.1rem);
	line-height: clamp(2rem, 2.5rem, 2.6rem);
	width: 90%;
}

.sticky.connexion::before{
	background-color: var(--bleufond);
	content: "";
	display: block;
	height: 1.5rem;
	width: 100%;
}
.connexion .nav-after::after {
	background-color: var(--bleugadifonce);
	content: "";
	display: block;
	height: 1rem;
	margin-top: 2rem;
	width: 100%;
}
.col-intro {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.row-form {
	align-items: end;
}
.row-form-create {
	padding-top: 2rem;
	justify-content: end;
}
.link-video {
	align-items: center;
	color: var(--grisgadi);
	display: none;
	font-size: clamp(2rem, 2.4rem, 3rem);
	padding: 20px 0 0 0;
	text-transform: uppercase;
}
.link-video i {
	margin-left: 1rem;
}
.link-video i::before {
	font-size: clamp(3rem, 4.2rem, 4.2rem);
}
.form-control {
	border: 2px solid var(--bleugadisat); /* Bordure bleue */
}
label {
	display: block;
	text-indent: -999em;
}
input.identifiant::placeholder,
input.mdp::placeholder{
	color: var(--bleugadisat);
	font-size: 1.5rem;
}

@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
	.row-form-create {
		justify-content: end;
	}
	.row-inputs {
		padding-bottom: 10rem;
	}
	.row-label {
		height: 0px;
	}
	.row-textuel {
		justify-content: space-between;
	}
	.row-textuel .col-md-40 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 2rem;
	}
}
@media screen and (max-width: 992px) {
	h1 {
		font-family: var(--medium);
		font-size: clamp(4rem, 4.1rem, 4.2rem);
		/* line-height: clamp(6rem, 6.5rem, 7rem); */
		text-transform: uppercase;
	}
}
@media screen and (min-width: 1200px) {
	.connexion main {
		padding: 10rem 0;
	}
	.connexion header::before {
		height: 2.5rem;
	}
	body.connexion header::after {
		height: 1.2rem;
	}
	.connexion .container {
		width: 58%;
	}
	.connexion input,
	.connexion button {
		width: 32%;
	}
	.nav-after .container {
		width: 84%;
	}
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
	.btn-account:hover {
		color: var(--bleugris);
		background-color: var(--grisgadi);
	}
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
	.connexion header::before {
		height: 4rem;
	}
	.connexion header::after {
		height: 1.6rem;
	}
}
