.container-portail{
  display: flex;
  justify-content: center; /* Centre les éléments horizontalement */
  align-items: center;    /* Centre les éléments verticalement */
  flex-wrap: wrap;        /* Permet aux éléments de passer à la ligne suivante */
}

.container-page{
  padding: 2rem;
}

/* Styles généraux */
.icon-button {
  margin-right: 0rem;
}
.divListeRechAuto {
  color: var(--bleugadisat);
  max-height: 200px;
  overflow-y: auto;
  width: 90%;
  z-index: 100;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 50px;
  width: calc(100% - 50px);
  z-index: 1000;
  background-color: white;
}
.liListeRechAuto{
  border-radius:0.2rem;
  cursor:pointer;
  display:flex;
  justify-content:space-between;
  margin:2rem;
  cursor:pointer;
  border-bottom: 1px solid var(--grisgadi);
  font-size: 1.125rem;
  padding-bottom: 2rem;
}

.ulListeRechAuto {
  padding:1rem;
  list-style:none;
}
.input-group-ref {
  width: 90%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.search-header {
  border-radius: 0 0.5rem 0.5rem 0;
  height: 5rem;
  width: 5rem;
}

.scan-icon {
  font-size: 30px;
  cursor: pointer;
  border: 1px solid var(--bleugadisat);
  padding: 1rem;
  color: var(--bleugadisat); 
  border-radius: 5px;
  margin-left: 1rem;
}

.resultat-recherche{
  padding: 0 2rem;
}

span.texte-resultat-recherche-ref,
span.texte-resultat-recherche-designation,
span.texte-resultat-recherche-fournisseur,
p.texte-resultat-recherche-prix{
  color: var(--bleugadisat);
}

span.texte-resultat-recherche-ref{
  font-family: var(--bold);
  font-size: clamp(1.5rem, 1.6rem, 1.7rem);
}

span.texte-resultat-recherche-fournisseur{
  margin-top: 0.5rem;
}

span.texte-resultat-recherche-designation,
span.texte-resultat-recherche-fournisseur,
p.texte-resultat-recherche-prix
{
  font-size: clamp(1.2rem, 1.3rem, 1.4rem);
  display: flex;
  align-items: center;
  justify-content: center;
}
.texte-resultat-recherche-designation{
  display: block;
}
span.stock-refArticle{
  color: var(--vert00B100);
  font-size: clamp(1.1rem, 1.2rem, 1.3rem);
}
span.ruptureStock-refArticle{
  color: #ff0000;
  font-size: clamp(1.1rem, 1.2rem, 1.3rem);
}

.img-container-refArticle{
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
}

.img-resultatRecherche{
  width: 13.4rem;
  display: flex;
  overflow: hidden;
  padding: 0px 1rem 1rem 0;
}

hr {
  border: none;
  border-top: 1px solid var(--bleugadisat); /* Noir, ajustez selon vos besoins */
  margin: 10px 0; /* Espacement vertical autour du hr */
  margin-right: 2rem;
}

.input-group-client-article {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  position: relative;
}

.img-texte-resultat{
  align-items: center;
}

.recherche-article{
  border-left: none;
  flex-grow: 1;
  border-radius: 0 5px 5px 0;
  border: 0px;
  color: var(--grisgadi);
  font-size: 1.5rem;
}
.nom-page{
  background-color: var(--bleugadisat);
  height: 5.9162rem;
  justify-content: space-between;
  align-items: center;
}

.nom-page h1{
  color: var(--blanc);
  padding: 2rem;
  font-size: 1.875rem;
  font-family: var(--regular);
  text-transform: capitalize;
}

.nom-page i::before{
  color: var(--bleufond);
  padding: 3rem;
  font-size: 1.4881rem;
}
 
.input-group-client-article i {
  height: 100%;
  width: 5rem;
}

.input-group-client-article i::before{
  font-size: 3rem;
  height: 100%;
  width: 100%;
  background-color: var(--bleufond);
  color: var(--blanc);
  border-radius: 5px 0 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
i.icon.icon-article_1::before,
i.icon.icon-client::before{
  width: 5rem;
}
.btn-valide-active{
  border: 4px solid var(--bleufond);
  color: var(--bleufond);
  border-radius: 5px;
  width: 35%;
  padding-left: 4rem;
  margin: 2rem 0;
}

/* Bouton désactivé (gris) */
.btn-valide-disabled {
  color: var(--grisgadi);
  border: 4px solid var(--grisgadi);
  padding-left: 4rem;
  margin-top: 2rem;
  border-radius: 5px;
  cursor: not-allowed;
  width: 35%;
}

.input-group-ref .scan::before{
  font-size: 3rem;
  height: 100%;
  width: 5rem;
  background-color: var(--bleugadisat);
  color: var(--blanc);
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-article{
  box-shadow: 0px 0px 6px var(--noirshadow);
}

.img-container-refArticle i::before{
  color: var(--grisinfo);
  font-size: 2.09rem;
}

.panel-recherche-article{
  background-color: var(--blanc);
	border-radius: 5px 5px 0 0;
	overflow: hidden;
}

.panel-recherche-stock-prix{
  background-color: var(--grisinfo);
	border-radius: 0 0 5px 5px;
	display: flex;
	align-items: center; /* Centrer les images horizontalement */
	overflow: hidden;
  margin-bottom: 2rem;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  opacity: 100%;
}

.panel-recherche-stock-prix .prix{
  background-color: var(--bleufond);
  color: var(--blanc);
  border-radius: 5px;
  border: 1px solid var(--grisinfo);
  max-width: 30%;

  padding: 1rem;
  font-family: var(--bold);
  margin-top: -1.8rem;
}

.panel-recherche-stock-prix .magasin,
.panel-recherche-stock-prix .groupe,
.panel-recherche-stock-prix .cde-art{
  background-color: var(--blanc);
  color: var(--bleugadisat);
  border-radius: 5px;
  border: 1px solid var(--grisprez);
  width: auto;
  padding: 1.5rem 1rem 1.5rem 1rem;
  text-align: center;
}

.stock-mag,
.stock-grp,
.art-cde{
  text-transform: uppercase;
  color: var(--bleugadisat);
  margin-top: 1rem;
  text-align: center;
}

.mag,
.grp,
.cde{
  display: flex;
  flex-direction: column;
}

.form-control.selected {
  color: var(--bleugadisat); /* Couleur plus foncée du texte */
}

/* Styles pour les téléphones */
@media only screen and (max-width: 767px) {

  .scan-icon {
    font-size: 32px;
    padding: 0.8rem;
  }
}

/* Styles pour les iPads */
@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .scan-icon {
    font-size: 30px;
    padding: 0.9rem;
  }
  .img{
    margin-left: 1rem;
  }
  
  span.texte-resultat-recherche-ref{
    font-size: clamp(1.9rem, 2.0rem, 2.1rem);
  }
  span.texte-resultat-recherche-designation,
  span.texte-resultat-recherche-fournisseur,
  p.texte-resultat-recherche-prix
  {
    font-size: clamp(1.5rem, 1.6rem, 1.7rem);
  }
  span.stock-refArticle{
    font-size: clamp(1.3rem, 1.4rem, 1.5rem);
  }
  .img-resultatRecherche {
    width: 18.4rem;
    height: 8.2rem;
  }
}