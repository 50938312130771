h1.titreModule{
  color: var(--bleugadisat);
}
.titreModule {
  font-size: 2rem;
  line-height: 3rem;
}
.text-center {
  text-align: center;
}
.module-banner {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #f2f2f2;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  top: 30%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  padding: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 2rem;
  border-radius: 5px;
  position: relative;
  min-height:50rem;
  height:auto;
  margin-top:20rem;
}

.modal-content label{
  font-size:1.6rem;margin-top:5rem;
}
.modal-content input{
  font-size:1.6rem;
  /* margin-top:5rem; */
  border-radius: 5px;
  border: 1px solid #041D5B;
}

video {
  width: 100%;
  max-width: 600px;
}

.layout {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

h2.texte-scan{
  font-size: 1.5rem;
}
p.result-scan{
  font-size: 1.5rem;
}