header {
	background-color: var(--bleugadisat);
}

.sticky::before {
	content: "";
	display: block;
	height: 4rem;
	width: 100%;
}

header.nav-after {
	padding-top: 4rem;
}

.header-header {
	background-color: var(--bleugadisat);
	position: relative;
	z-index: 1;
}

.header-panel {
	margin: 0 auto;
	width: 87.5%;
	position: relative;
}

.header-panel .input-group {
	position: relative;
	display: inline-flex;
}

.input-group {
	margin: 1rem;
	display: flex;
	align-items: center;
	border-radius: 4px 0 0 4px;
	overflow: hidden;
}

.header-panel .input-group {
	margin: 0;
	margin-top: 1.5rem;
	position: relative;
	display: inline-flex;
}

.logo-header {
	display: flex;
	width: 30rem;
}

.desktop-logo-colibrisco {
	height: inherit;
	-o-object-fit: contain;
	object-fit: contain;
	width: 100%;
	margin-top: -12rem;
}

.mobile-logo-colibrisco {
	display: none;
}

.desktop-logo-autoref {
	height: inherit;
	-o-object-fit: contain;
	object-fit: contain;
	width: 10%;
	margin-top: -3rem;
	margin-left: 88rem;
	margin-bottom: 15rem;
}

.titre {
	margin-top: 1rem;
	margin-left: 34rem;
	text-decoration: none;
}

.navigation-secondaire {
	align-items: center;
	display: flex;
	height: 5.8rem;
	justify-content: space-between;
	margin: 0 auto;
	position: relative;
}

.fa-magnifying-glass:before {
	margin-left: 2rem;
}
@media screen and (max-width: 576px) {
	.logo-header img {
			width: 80%;
	}
	.logo-header {
		justify-content: center;
}
}
/* Media Queries pour les écrans de téléphone */
@media (max-width: 767px) {
	.mobile-logo-colibrisco {
		display: block;
		height: inherit;
		-o-object-fit: contain;
		object-fit: contain;
		margin-top: -2rem;
		width: 100%;
		margin-left: 5rem;
	}

	.titre {
		margin-top: -4rem;
		margin-left: -16rem;
	}

	.desktop-logo-colibrisco {
		display: none;
	}

	.mobile-logo-autoref {
		height: inherit;
		-o-object-fit: contain;
		object-fit: contain;
		margin-top: 0rem;
		margin-left: 15rem;
		width: 13rem;
		margin-bottom: 5rem;
	}

	.desktop-logo-autoref {
		display: none;
	}
}

/* Media Queries pour les écrans d'iPad */
@media (min-width: 768px) and (max-width: 1024px) {
	.mobile-logo-autoref {
		display: none;
	}

	.desktop-logo-colibrisco {
		display: block;
		height: inherit;
		-o-object-fit: contain;
		object-fit: contain;
		width: 80%; /* Ajuste la largeur pour les écrans d'iPad */
		margin-top: -9rem; /* Ajuste la marge pour les écrans d'iPad */
		margin-left: -10rem;
	}

	.mobile-logo-colibrisco {
		display: none;
	}

	.titre {
		margin-top: 0;
		margin-left: 10rem; /* Ajuste la marge pour les écrans d'iPad */
	}

	.desktop-logo-autoref {
		display: none;
	}
}