/****************/
/* Normaliz CSS */
/****************/

* {
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
	/* on ne tient plus compte de la hauteur ni de largeur du contenu de chaque bloc*/
}
body {
	background-color: var(--blanc);
	font-family: var(--regular), sans-serif;
	font-weight: 400;
	color: var(--bleugadisat);
	/* background-color: var(--bleugadisat); */
}
html {
	font-size: 62.5%;
}
html,
body {
	overflow-x: hidden;
	margin: 0;
	padding: 0;
	height: 100%;
}
.label{
	color:#000;
	display: block;
	text-indent: 0em;
	font-size:3rem;
	margin-bottom: 2rem;
	margin-top: 2rem;
}