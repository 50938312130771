:root {
	--blanc: #fff;
	--bleugadisat: #041d5b;
	--bleugadifonce: #03143e;
	--bleugris: #41517b;
	--bleufond: #2175ff;
	--bleufondhover: #6d98df;
	--bleutypo: #2189ff;
	--bleutypofonce: #374262;
	--jauneE6AD00: #e6ad00;
	--grisclair: #f5f5f5;
	--grisgadi: #a9aeb6;
	--grisprez: #e0e0e0;
	--grisarrow: #f3f5fa;
	--grisinfo: #CACDD1;
	--vert00B100: #00b100;
	--vert27D3AA: #27D3AA;
	--alertdangertypo: #721c24;
	--alertdangerfond: #f8d7da;
	--alertdangerborder: #f5c6cb;
	--alertprimarytypo: #004085;
	--alertprimaryfond: #cce5ff;
	--alertprimaryborder: #b8daff;
	--rougepl: #e52321;
	--rougehoverpl: #8b0000;
	--noirshadow: #00000029;
	--shadow: 0 0 0.6rem var(--noirshadow);
	--bleugadifonce75: #03143e75;
}