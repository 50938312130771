.icon-rond-societe{
  width: 7rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  font-size: 4rem;
  color: var(--blanc);
  background-color: var(--bleufond);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
i.icon-rond-societe{
  font-size: 3rem;
}
.container-societe{
  display: flex;
  flex-direction: column;
  /* display: none; */
}
.societe-adh{
  /* padding-top: 2rem; */
  padding: 1rem 2rem;
}
.no-padding-top {
  padding-top: 0 !important;
}
.option-item {
  background-color: transparent;
  color: var(--blanc);
  text-transform: uppercase;
  font-size: 2rem;
  padding: 2rem;
  font-family: var(--bold);
}

.option-item span{
  display: block;
  padding-top: 1rem;
  font-family: var(--regular);
  font-size: 1rem;
}

.selection{
  color: var(--blanc);
  /* display: block; */
}

.portail-selected {
  background-color: var(--grisclair);
  color: var(--bleufond);
}
.portail-selected .icon-rond-societe{
  background-color: var(--grisprez);
  width: 5rem;
  height: 5rem;
}
.portail-selected i.icon-rond-societe{
  color: var(--bleufond);
  font-size: 2rem;
}
.portail-selected .societe-adh{
  /* padding-bottom: 1rem; */
  padding: 0 2rem 1rem 2rem;

}
.portail-selected .option-item{
  color: var(--bleufond);
}

.input-group-magasin,
.input-group-client-stat{
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  position: relative;
}
.input-group-magasin i::before,
.input-group-client-stat i::before {
  font-size: 3rem;
  height: 100%;
  width: 100%;
  background-color: var(--bleufond);
  color: var(--blanc);
  border-radius: 5px 0 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-group-magasin i,
.input-group-client-stat i  {
  height: 100%;
  width: 5rem;
}
i.icon.icon-ajouter.ajouter-magasin.icon-filled::before {
  background-color: transparent;
  color: var(--vert27D3AA); /* Couleur pour l'icône lorsque l'input est rempli */
}

i.icon.icon-ajouter.ajouter-magasin.icon-empty::before {
  background-color: transparent;
  color: var(--grisgadi); /* Couleur grise par défaut lorsque l'input est vide */
  padding-right: 2rem;
}
.recherche-magasin,
.recherche-client {
  border-left: none;
  flex-grow: 1;
  border-radius: 0 5px 5px 0;
  border: 0px;
  color: var(--grisgadi);
  font-size: 1.5rem;
}
i.icon.icon-store::before, 
i.icon.icon-client::before,
i.icon.icon-secteur::before,
i.icon.icon-univers::before,
i.icon.icon-annee::before {
  width: 5rem;
}
/* .icon-valid {
  color: var(--vert00B100);
}
.icon-empty {
  color: var(--grisgadi);
} */
.button-selection-magasin{
  justify-content: center;
}
.btn-valide-selection{
  color: var(--bleufond);
  border: 4px solid var(--bleufond);
  padding-left: 4rem;
  margin-top: 2rem;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
}
.listeRechDeroulante{
  color: var(--bleugadisat);
  max-height: 200px;
  overflow-y: auto;
  width: 90%;
  z-index: 100;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 50px;
  /* width: calc(100% - 100px); */
  z-index: 1000;
  background-color: white;
  width: 70%;
}

.form-control.recherche-magasin.selected {
  color: var(--bleugadisat); 
  font-size: 1.2rem;
}
.icon-fleche-down.rotatedMag::before,
.icon-fleche-down.rotatedSec::before,
.icon-fleche-down.rotatedUni::before,
.icon-fleche-down.rotatedAnn::before{
  transform: rotate(180deg);
}
.selection-mag{
  padding: 0 2rem;
}

p.titre-graph-resultat{
  color: var(--bleugadisat);
  text-transform: uppercase;
  font-size: 1.3rem;
  font-family: var(--bold);
  justify-content: center;
  width: 100%;
  padding-top: 2rem;
}

.nom-client-graph{
  color: var(--bleugadisat);
  text-transform: uppercase;
  font-size: 1.55rem;
  justify-content: center;
  font-family: var(--regular);
}

.chart-container{
  background: var(--blanc);
  box-shadow: 0 0 15px var(--noirshadow);
}
canvas {
  width: 100vw !important; 
  height: 100vh !important;
  padding: 2rem;
}

.icon-fleche-down::before {
  position: absolute;
  right: 1rem; /* Ajustez la position si nécessaire */
  top: 0;
  /* transform: translateY(-50%); */
  width: 1rem;
  height: 1rem;
  /* transform: rotate(45deg) translateY(-50%); */
  pointer-events: none;
  font-family: "colibri" !important;
}

.form-control-selection{
  position: relative;
  font-family: var(--regular) !important;
  width: calc(100% - 100px);
}

.form-control-selection i::before{
  background-color: transparent;
  border-radius: unset; 
  color: var(--bleufond);
  width: unset;
  padding: unset;
  font-size: 1rem;
}



